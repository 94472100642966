/**
 * @copyright Copyright 2023-2024 Epic Systems Corporation
 * @file Bare-bones model for showroom
 * @module Epic.AppOrchard.Types.Showroom
 */

import { ShowroomSortOrder } from "ao/components/Showroom/frameworkComponents/GeneralComponents/TeaserFilteringControls";
import {
	ClientApplicationStatus,
	ConsumerTypes,
	DownloadApprovedStatus,
	IGenericAPIResponseWrongCasing,
	InstallStatus,
	QuestionnaireType,
} from "ao/data";
import { ContactSubject, QuestionType } from "ao/types";
import * as CSS from "csstype";
import { ReactNode } from "react";

export enum GalleryCardStyle {
	Undefined = 0,
	IconCard = 5, //icon, title & summary
	IconCardSummaryOnly = 6, //icon & summary
	IconCardNoFields = 7, //icon only
	ComingSoonCardTitleOnly = 8,
	ComingSoonCardIconOnly = 9,
}

export enum GalleryStageSource {
	App = 1,
	ConnectionHub = 2,
	Listing = 3,
}

export enum GalleryListingStyle {
	Undefined = 0,
	AppFull = 1,
	Partner = 2,
	People = 3,
	ExternalLink = 4,
	AppFullWithDescription = 6,
}

export enum GalleryListingSearchType {
	FindAny,
	FindAnyPrioritizeExactAndStart,
	FindAll,
	FindExact,
}

export enum GalleryHeaderStyle {
	Undefined = 0,
	TitleOnly = 1,
	IconOnly = 2,
	TitleIcon = 3,
	TitleButtons = 4,
}

export enum TierQueryParamKeys {
	Id = "id",
	SearchString = "q",
	SortOrder = "o",
	Categories = "c",
	ChildTierId = "t",
}

/** Includes fields use by every Showroom listing type (App, Non-App, and People)*/
export interface IShowroomListingUniversalModel
	extends IShowroomAppDownloadModel,
		IShowroomNonAppListingModel {}

/** Model which holds props used by all Showroom listing types */
export interface IShowroomBaseListingModel {
	id: number;
	appId?: number;
	thumbnail: IShowroomScreenshot;
	screenshots: IShowroomScreenshot[];
	categoryIds: number[];
	primaryCategoryName: string;
	primaryCategoryId: number;
	hideName: boolean;
	hideOrgName: boolean;
	disclaimer: string;
	stageDisclaimer: string;
	stageId: number;
	topLevelSingularName: string;
	listingDate: Date | null;
	showroomYears: string[];
	resourceLinks: IShowroomResourceLink[];
}

/** Model which holds non-app listing props */
export interface IShowroomNonAppListingModel extends IShowroomBaseListingModel {
	name: string;
	website: string;
	orgId?: number;
	orgName: string;
	orgWebsite: string;
	summary: string;
	description: string;
	contactEmail: string;
}

/** Model which holds app listing props */
export interface IShowroomAppDownloadModel extends IShowroomBaseListingModel {
	appModel: IShowroomApp;
	appVersions: IAppVersionWithDetails[];
	isDownloaded: boolean;
	isDownloadedByParent: boolean;
	participantTermsId: number;
	acceptedParticipantTermsId: number;
	downloadedOn: string;
	downloadedBy: string;
	refreshTokenValidity: number;
	accessTokenValidity: number;
	disableIndefiniteAccess: boolean | null;
	vendorApproved: DownloadApprovedStatus;
	installStatus: InstallStatus;
	showARQInListing: boolean;
	showDUQInListing: boolean;
	prodVersion: IAppVersionDownload;
	nonProdVersion: IAppVersionDownload;
	requestedVersion: IAppVersionDownload;
	notImplementingNowDetails: INotImplementingNowDecision;
	interestedCount: number;
	interestedOn: string;
	orgHasPurchaser: boolean;
	apiLicenseTermsId: number;
	previousAPILicenseExists: boolean;
	isUSCDIDownloadSet: boolean;
	appIsOnDownloadAllowList: boolean;

	liveOrgCount: number;
	liveOrgList: string[];
	installingOrgCount: number;
	installingOrgList: string[];

	defaultRefreshTokenValidity?: number;
	defaultAccessTokenValidity?: number;
	defaultDisableIndefiniteAccess?: boolean;

	hasPotentiallyUnsafeProviderFacingDCR: boolean;
	isSyncDisabled: boolean;
}

export interface IShowroomApp {
	id: number;
	organizationId: number;
	orgName: string;
	name: string;
	status: ClientApplicationStatus;
	liveOn: Date | null;
	version: string;
	website: string;
	summary: string;
	description: string;
	consumerType: ConsumerTypes;
	appGroupId: number;
	agreementId: number;
	refreshTokensRequired: boolean;
	isConfidentialClient: boolean;
	isEpicOnFhirApp: boolean;
	isAutoDownloadedClient: boolean;
	usesOAuth2: boolean;
	showDownloadLink: boolean;
	ownDownloadExists: boolean;
	requiresFutureEpicVersion: boolean;
	hideEndorsementWarning: boolean;
	hasIndefiniteAccess: boolean;
	implementationGuideUrl: string;
	hasTCDocuments: boolean;
	outgoingFunctionalities: string[];
	kitApiGroups: IGalleryApi[];
	incomingApis: IGalleryApi[];
	subspaceEvents: IGalleryApi[];
	contentSchema: INameIdPair;
	thumbnail: IShowroomScreenshot;
	screenshots: IShowroomScreenshot[];
	isRelatedOrgApp: boolean;
}

export interface IShowroomScreenshot {
	id: number;
	imageData: string;
	caption: string;
	mimeType: string;
	width: string;
	height: string;
	order: number;
}

export interface IAppVersionWithDetails extends INameIdPair {
	isInShowroom: boolean;
}

export interface IAppVersionDownload extends INameIdPair {
	licensedOn: string;
	licensedBy: string;
	vendorApproved: DownloadApprovedStatus;
	isInShowroom: boolean;
}

export interface IShowroomResourceLink {
	displayName: string;
	url: string;
}

export interface IAppTeaserModel {
	listingId: number;
	appId: number;
	name: string;
	orgId: number;
	orgName: string;
	summary: string;
	description: string;
	website: string;
	contact: string;
	hashId: number;
	hasDownload: boolean;
	listingDate: Date;
	prodClientId: string;
	nonProdClientId: string;
	categoryIds: number[];
	primaryCategoryName: string;
	hideName: boolean;
	hideOrgName: boolean;
}

export interface IAppTeaserModelWithStageAndParentStage extends IAppTeaserModel {
	stage: IGalleryStageModel;
	parentStage?: IGalleryStageModel;
}

export interface IFilterValues {
	id: number;
	name: string;
}

export interface IGalleryStageModel {
	id: number;
	groupName: string;
	singleName: string;
	description: string;
	cardStyle: GalleryCardStyle;
	listingStyle: GalleryListingStyle;
	iconPath: string;
	headerImagePath: string;
	status: number;
	parentId: number;
	canBeParent: boolean;
	isTopLevel?: boolean;
	source: GalleryStageSource;
	teasers: IAppTeaserModel[];
	textColor: string;
	backgroundColor: string;
	backgroundColor2: string;
	disclaimer: string;
	showGalleryIcon?: boolean;
	contactSubject_Id?: ContactSubject;
	showContactToAll?: boolean;
	headerStyle: GalleryHeaderStyle;
	flattenTier: boolean;
	showWhenEmpty: boolean;
	learnMoreUrl: string;
	headerSubtext: string;
	listingConjunction: string;
	includeLiveAppsWhenFiltering?: boolean;
	sortOptions: IGalleryStageSortOption[];
	details: string;
	resourceHeaderText: string;
}

export interface IGalleryStageSortOption {
	sortOption: ShowroomSortOrder;
	galleryStage_Id: number;
	isDefault?: boolean;
}
export interface IGalleryStageWithChildren extends IGalleryStageModel {
	childStages: IGalleryStageWithChildren[];
}

export const stageHasComingSoonCardStyle = (stage: IGalleryStageModel | null | undefined): boolean =>
	[GalleryCardStyle.ComingSoonCardIconOnly, GalleryCardStyle.ComingSoonCardTitleOnly].includes(
		stage?.cardStyle ?? GalleryCardStyle.Undefined,
	);

export interface ILinearNavigationModel {
	id: number;
	name: string;
	parentName?: string;
}

export interface IGallerySearchResults {
	resultGroups: IGallerySearchResultGroup[];
	resultCount: number;
}

export interface IGallerySearchResultGroup {
	topLevelStageName: string;
	topLevelDisclaimer: string;
	order: number;
	listings: IGalleryListingSearchResult[];
}

export interface IGalleryListingSearchResult {
	teaser: IAppTeaserModel;
	currentStageName: string;
	subStageName: string;
	subStageColor: string;
	subStageGalleryIcon?: string;
	matchingText: string[];
	matchStrength: number;
	isClickable: boolean;
	externalLink?: string;
}

export interface IGalleryListingSearchData {
	boldedText: string;
	matchStrength: number;
}

export interface IStageRefDictionary {
	[id: number]: React.RefObject<HTMLDivElement>;
}

export interface IGalleryPageSection {
	element: ReactNode;
	backgroundColor: CSS.Property.Color;
}

export enum GalleryPageStyle {
	WhiteHeaderGradientContents,
	ColorHeaderWhiteContents,
	NoHeaderGradientContents,
	ImageHeaderSolidContents,
}

//#region App Listing Interfaces

export interface IGalleryApi extends INameIdPair {
	warning: string;
}

interface INameIdPair {
	id: number;
	name: string;
}

export interface ICategory {
	id: number;
	name: string;
	children?: any[];
}

export interface INotImplementingNowDecision {
	userName: string | null;
	instant: Date;
	publicReason: string;
	privateReason: string | null;
	appVersionId: number;
}

export interface INominateAppResponse {
	InterestedCount: number;
	InterestedOn: string;
}

export interface IAppUsageStatsResponse {
	liveOrgCount: number;
	liveOrgList: string[];
	installingOrgCount: number;
	installingOrgList: string[];
	timeAvailableValue: number;
	timeAvailableUnit: string;
}

//#endregion

//#region Review models

export interface IReviewsRequestModel {
	AppId: number;
	Page: number;
	RoleInputs: string[];
	RatingChoices: number[];
	SingleReview?: number;
	VersionAppId?: number;
	EndUserChoice?: ReviewEndUserSelection;
	StakeChoice?: ReviewStakeSelection;
	CompensationChoice?: ReviewCompensationSelection;
	SortChoice?: ReviewSortChoice;
}

export interface IReviewsResponseModel extends IGenericAPIResponseWrongCasing {
	Reviews: IReviewForApp[];
	PageSize: number;
	FilteredCount: number;
}

export interface IHighlightedReviewsResponseModel extends IGenericAPIResponseWrongCasing {
	Reviews: IReviewForApp[];
}

export interface IReviewMetadataResponseModel extends IGenericAPIResponseWrongCasing {
	ReviewCount: number;
	AverageRating: number;
	RatingCounts: number[];
	Roles: string[];
	LiveOrgCount: number;
	LiveOrgList: string[];
	InstallingOrgCount: number;
	InstallingOrgList: string[];
	TimeAvailableValue: number;
	TimeAvailableUnit: string;
	InterestedUserCount: number;
	ReviewCountThreshold: number;
}

export interface IReviewForApp {
	Id: number;
	Roles: string[];
	StarRating: number;
	Comments: string[];
	EndUserOfApplication: ReviewEndUserSelection;
	FeedbackPerspective: ReviewOpinionSelection;
	StakeInOrganization: ReviewStakeSelection;
	CompensatedForReview: ReviewCompensationSelection;
	Prompts: IReviewPrompt[];
	OrganizationId: number;
	OrganizationName: string;
	ReviewDate: string;
	OwnReview: boolean;
	MarkedHelpful: boolean;
	HelpfulCount: number;
	RecentResponse: boolean;
	AppDeactivatedByOrg: boolean;
	Version: string;
	VersionAppId: number;
}

export interface IReviewPrompt {
	Id: number;
	Text: string;
	Response: ReviewResponseValue;
	OrderNumber: number;
}

export enum ReviewEndUserSelection {
	Null = 0,
	Yes = 1,
	No = 2,
}

export enum ReviewOpinionSelection {
	Null = 0,
	Personal = 1,
	Organization = 2,
}

export enum ReviewStakeSelection {
	Null = 0,
	Personal = 1,
	Organization = 2,
	Unknown = 3,
}

export enum ReviewCompensationSelection {
	Null = 0,
	Personal = 1,
	Organization = 2,
	Unknown = 3,
}

export enum ReviewResponseValue {
	Null = 0,
	Positive = 1,
	Negative = 2,
}

export enum ReviewSortChoice {
	NewToOld = 0,
	OldToNew = 1,
	RatingLowToHigh = 2,
	RatingHighToLow = 3,
}

//#endregion

//#region Questionnaires models

export interface IShowroomQuestionnaireViewModel {
	applicationName: string;
	organizationName: string;
	questionnaires: IShowroomQuestionnaire[];
	/** whether the currently request questionnaire or app is invalid for some reason and we should redirect to Showroom homepage */
	shouldRedirectHome: boolean;
	shouldRedirectToApp: boolean;
	hideEndorsementWarning: boolean;
	topLevelStageName: string;
}

export interface IShowroomQuestionnaire {
	id: number;
	versionId: number;
	versionName: string;
	lastEditDate: string;
	type: QuestionnaireType;
	categories: IQuestionnaireCategory[];
}

export interface IQuestionnaireCategory {
	id: number;
	title: string;
	description: string;
	questions: IQuestionnaireQuestion[];
}

export interface IQuestionnaireQuestion {
	id: number;
	text: string;
	responseText: string;
	indent: boolean;
	orderNumber: number;
	type: QuestionType;
}

//#endregion

export enum BackLinkType {
	Undefined = 0,
	ToDownload = 1, //Back to Download
	ToPickedApps = 2, //To Picked Apps
	ToSearch = 3, //Back to Search
}
