/**
 * @copyright Copyright 2020-2024 Epic Systems Corporation
 * @file general types used
 * @module Epic.AppOrchard.Types
 */

import { GridProps } from "@chakra-ui/react";
import { isNullOrEmpty } from "ao/utils/helpers";
import {
	ClientApplicationStatus,
	ConsumerTypes,
	DownloadApprovedStatus,
	EnvironmentType,
	InstallStatus,
	PublicKeysAllowed,
} from "../data";
import { IAppModel } from "./buildApps";

export enum OrganizationStatus {
	Undefined = 0,
	Interested = 1,
	OrganizationDenied = 2,
	OrganizationApproved = 3,
	RequestingProgramLevel = 4,
	ProgramLevelDenied = 5,
	Invoicing = 6,
	PaymentFailed = 7,
	ProgramLevelApproved = 8,
	VendorDiscontinued = 9,
}

export enum ProgramLevel {
	Undefined = 0,
	USCDIMember = 50,
	BronzeMinus = 90,
	Greenhouse = 100,
	Garden = 200,
	Terrace = 300,
	EpicCommunityMember = 500,
	Epic = 1000,
}

export enum UserAccountType {
	Undefined = 0,
	QueuedForAdminApproval = 10,
	PendingApproval = 50,
	VendorDeveloper = 100,
	EpicUser = 200,
	VendorAdmin = 400,
	EpicAdmin = 800,
	SuperAdministrator = 1000,
}

export enum OrganizationSelfAuditStatus {
	Completed = 0,
	Open = 1,
	InGrace = 2,
	PastGrace = 3,
}

export enum OrganizationUserAssociation {
	Undefined = 0,
	InitialContact = 1,
	EpicOwner = 2,
	DirectEmployee = 3,
	RemoteAccess = 4,
	EpicSupport = 5,
}

export enum InterestApprovalFailureReason {
	None = 0,
	PublicEmailDomain = 1,
	DisallowedCountry = 2,
	NegativeQuestionnaireResponse = 3,
}

export enum EnrollmentBatonOwner {
	Undefined = -1,
	Vendor = 0,
	Epic = 1,
}

export enum TermsType {
	Undefined = 0,
	AppDeveloperAgreement = 1,
	AppCustomerAgreement = 2,
	AppMarketProgramDetails = 3,
	AppAgreement = 4,
	USCDIonFHIR = 5,
	APILicenseAgreement = 6,
	ConnectionHubAgreement = 7,
	PrivateAPIAccess = 8,
	ToolboxAgreement = 9,
}

export enum SqlApiType {
	Kit = 0,
	Clarity = 1,
}

export interface IUserSecurity {
	userId: number | null;
	displayName: string;

	email: string;
	userAccountType: UserAccountType;
	userOrganizationId: number | null;
	userDBOrganizationId: number | null;
	organizationName: string;

	organizationCountry: string;
	organizationGovernanceMessage: string;
	organizationGovernanceUrl: string;

	epicCommunityMemberId: number | null;
	programLevel: ProgramLevel;
	organizationStatus: OrganizationStatus;

	getsAppLicensesFromOrganization_Name?: string;

	canPurchase: boolean;
	canManageUsers: boolean;
	canSubmit: boolean;
	canActivateClients: boolean;
	canCreateRemoteApps: boolean;
	canManageDocumentation: boolean;
	canManageSigil: boolean;
	canCreateNewAppVersions: boolean;
	canManageStargate: boolean;
	canManageQuestionnaire: boolean;
	canManageConnectors: boolean;
	canViewApiConnectors: boolean;
	canManageConnectorsInterface: boolean;
	canManageConnectorsAppsOrgs: boolean;
	canManageOpenEpicApiPricing: boolean;
	canManageAgreements: boolean;
	canToggleOpenEpicApiPricingParams: boolean;
	canViewAllRelatedOrgApps: boolean;
	canManageApiCatalogs: boolean;
	canEditLiveApps: boolean;

	isEpicCustomer: boolean;
	isSuperAdministrator: boolean;
	isEpicAdministrator: boolean;
	isEpicUser: boolean;
	isVendorUser: boolean;
	isVendorAdministrator: boolean;
	isInCustomerMode: boolean;

	countryId?: number | null;
	activeMembers?: number | null;
	concurrentUsers?: number | null;
	organizationType?: PricingOrganizationType | null;
	apiLicenseType?: PricingApiLicenseType | null;
	currencyId?: number | null;

	disabledCopyCGApp?: boolean;
	ActiveAppListingCount: number;
	FreeAppListingThreshold: number;
	aoppEmail?: string;
	menus: ParentMenu[];
	/** set to true if the current user hasn't viewed the latest what's new */
	showWhatsNew: boolean;
	/** set to true if there is a what's new document present */
	hasWhatsNew: boolean;
	userPhotoSrc: string;
	organizationAuditStatus: OrganizationSelfAuditStatus | null;
	agreedToPrivacyPolicy: Date | null;
	hasAccessToClarityApis: boolean;

	isConnectionHubEnabled?: boolean;
}

export enum SecurityPoints {
	CanManage = 1,
	CanPurchase = 2,
	CanSubmit = 4,
	CanActivateClients = 8,
	CanCreateRemoteApps = 16,
	CanManageDocumentation = 32,
	CanManageSigil = 64,
	CanCreateNewAppVersions = 128,
	CanManageStargate = 256,
	CanManageQuestionnaire = 512,
	CanManageConnectors = 1024,
	CanManageOpenEpicApiPricing = 2048,
	CanManageAgreements = 4096,
	CanToggleOpenEpicApiPricingParams = 8192,
	CanManageConnectorsAppsOrgs = 16384,
	CanViewAllRelatedOrgApps = 32768,
	CanManageApiCatalogs = 65536,
	CanEditLiveApps = 131072,
}

export enum PricingOrganizationType {
	Traditional = 1,
	Payer = 2,
}

export enum PricingApiLicenseType {
	USCDI = 1,
	ALaCarte = 2,
}

export interface IMenu {
	id: number;
	name: string;
	link: string;
}

export type ParentMenu = Omit<IMenu, "link"> & { children: IMenu[] };

export interface ISiteInfo {
	siteName: string;
	programDisplayName: string;
	clientGardenUrl?: string;
	fhirUrl?: string;
	openEpicUrl?: string;
	myChartUrl?: string;
	epicShareUrl?: string;
	epicDotComUrl?: string;
	cosmosUrl?: string;
	isGalleryEnabled?: boolean;
	isBuildAppsCategoryEnabled?: boolean;
	isRequestPricingEnabled?: boolean;
	isConnectorCreateEnabled?: boolean;
	isConnectorEditEnabled?: boolean;
	isConnectorRollbackEnabled?: boolean;
	isResubmitILGAuditFileEnabled?: boolean;
	isChangeStatusEnabled?: boolean;
	isSpecialLicensingEnabled?: boolean;
	trackDowntimeStart?: Date;
	trackDowntimeEnd?: Date;
	isTrackDowntime?: boolean;
	isAdminSite?: boolean;
	googleAnalyticsTrackingId: string;
	recaptchaPublicKey: string;
	userInactivityLogoutTimeInMinutes: number;
	emc2BaseUrl: string;
	abacusBaseUrl: string;
	galleryTokens: IGalleryTokens;
	userWebUrl: string;
	guruBaseUrl: string;
	customerAgreementName: string;
	customerAgreementDisplayName: string;
	vendorServicesUrl: string;
	showroomUrl: string;
	showroomRelativeUrl: string;
}

export interface IGalleryTokens {
	galleryName: string;
	periodName: string;
	galleryBlurb: string;
	galleryDisclaimer: string;
}

export interface IPostMessageModel {
	Name: string;
	OrgName: string;
	Email: string;
	QueryType: number;
	Message: string;
	CaptchaResponse: string;
}

export interface IGenericResponseModel {
	success: boolean;
}

export interface IHasUnapprovedDownloadsResponse {
	Success: boolean;
	Data: boolean | false;
}

export interface ISaveDefaultOrgResponse {
	orgId: number;
}

export interface IGenerateClientIDResponse {
	clientId: string;
}

export enum SelectOptionTypeType {
	Option = 0,
	DefaultOptions = 1,
}

export type SelectOptionType = {
	readonly label: string;
	readonly value: string;
	readonly type?: SelectOptionTypeType | SelectOptionTypeType.Option;
};

export interface ITCDocumentInfo {
	documentId: number;
	documentName: string;
	extendedContentId: number;
	epicVersion: string;
	minEpicVersion: string;
	epicCode: number;
	masterFiles: string;
}
export interface IPreviewDownloadBuildResponseModel {
	packageInfo: ITCDocumentInfo[];
}

export interface INotImplementingNowDecision {
	userName: string | null;
	instant: Date;
	publicReason: string;
	privateReason: string | null;
	appVersionId: number;
}

export interface INotImplementingNowForm {
	publicReason: string;
	privateReason: string;
	notifyInterestedUsers: boolean;
}

export interface INomination {
	userName: string | null;
	instant: Date;
	appVersionId: number;
}

export interface IBasicApp {
	appId: number;
	appName: string;
	appOrgName: string;
	prodClientId: string;
	nonProdClientId: string;
	implementationGuideUrl: string;
	tesseractEnabled: boolean;
}

export interface INominatedApp extends IBasicApp {
	appAgreementId: number | null;
	nominations: INomination[];
	hasDownloadableBuild: boolean;
	notNowDecision: INotImplementingNowDecision | null;
	numLiveOrgs: number;
	sharedLiveOrgNames: string[];
	numInstallingOrgs: number;
	sharedInstallingOrgNames: string[];
	numReviews: number;
	requiresApiLicenseForDownload: boolean | null;
	appStatus?: ClientApplicationStatus; // default is assumed to be Active if undefined

	isAvailableInGallery: boolean;
}

export interface IDownloadRequest {
	userName: string;
	instant: Date;
}

export interface IInProgressApp extends IBasicApp {
	installStatus: InstallStatus | null;
	turboChargerDocumentIds: number[];
	newestAppVersion: number | undefined;

	downloadRequest: IDownloadRequest;
	downloadApprovedStatus: DownloadApprovedStatus;
	nonProdLicensedOn: Date | null;
	prodLicensedOn: Date | null;
	/** Whether the download is activated in Prod only. This can happen if it was approved for all environments, 
		and later on a different version was approved for just non PRD */
	enabledForProdOnly?: boolean;

	isAvailableInGallery: boolean;
	isNewestVersionAvailableInGallery: boolean;
}

export interface ILiveApp extends IBasicApp {
	turboChargerDocumentIds: number[];
	newestAppVersion: number | undefined;

	markedLiveOn: Date | null;
	markedLiveByUserName: string | null;
	markedLiveAutomatically: boolean;
	hasApiMetricsForOrg: boolean;
	hasApiStatsForOrg: boolean;
	numReviews: number;
	numReviewsForOrg: number;
	currentUserHasReview: boolean;

	isAvailableInGallery: boolean;
	isNewestVersionAvailableInGallery: boolean;
}

export enum CustomerAppChangeRequestType {
	Undefined = -1,
	NonProdActivation = 0,
	ProdActivation = 1,
	NonProdCredentialUpdate = 2,
	ProdCredentialUpdate = 3,
}

export interface ICustomerAppChangeRequest {
	value: CustomerAppChangeRequestType;
	name: string;
	helptext: string;
}

/** License for a customer app for their own system */
export interface ICustomerAppLicense {
	clientApplication_Id: number;
	organization_Id: number;
	isActivatedInProd: boolean | null;
	isActivatedInNonProd: boolean | null;
	prodLicensedOn: Date | null;
	nonProdLicensedOn: Date | null;
	prodLicensedByUserName: string | null;
	nonProdLicensedByUserName: string | null;
	/** if true, the key is present but was imported from Client Garden and we don't have the thumbprint to display */
	testPublicKeyBlobIsCSP: boolean;
	/** if true, the key is present but was imported from Client Garden and we don't have the thumbprint to display */
	prodPublicKeyBlobIsCSP: boolean;
	testJwtSigningPublicKeyThumbprint: string | null;
	prodJwtSigningPublicKeyThumbprint: string | null;
	testClientSecretHash: string | null;
	prodClientSecretHash: string | null;
	testClientSecretHash256: string | null;
	prodClientSecretHash256: string | null;

	/** whether license for "previous" version (currently activated version,
	 *  previous from perspective of new version activation) has credentials */
	hasPreviousNonProdCreds: boolean | null;
	/** whether license for "previous" version (currently activated version,
	 *  previous from perspective of new version activation) has credentials */
	hasPreviousProdCreds: boolean | null;

	changeRequestType: CustomerAppChangeRequestType | null;
	changeRequestedByUserName: string | null;
	changeRequestedOn: Date | null;
	changeRequestComment: string | null;
	availableChanges: ICustomerAppChangeRequest[];
}

export class CustomerAppLicense implements ICustomerAppLicense {
	clientApplication_Id: number = -1;
	organization_Id: number = -1;
	isActivatedInProd: boolean | null = false;
	isActivatedInNonProd: boolean | null = false;
	prodLicensedOn: Date | null = null;
	nonProdLicensedOn: Date | null = null;
	prodLicensedByUserName: string | null = null;
	nonProdLicensedByUserName: string | null = null;
	/** if true, the key is present but was imported from Client Garden and we don't have the thumbprint to display */
	testPublicKeyBlobIsCSP: boolean = false;
	/** if true, the key is present but was imported from Client Garden and we don't have the thumbprint to display */
	prodPublicKeyBlobIsCSP: boolean = false;
	testJwtSigningPublicKeyThumbprint: string | null = null;
	prodJwtSigningPublicKeyThumbprint: string | null = null;
	testClientSecretHash: string | null = null;
	prodClientSecretHash: string | null = null;
	testClientSecretHash256: string | null = null;
	prodClientSecretHash256: string | null = null;
	hasPreviousNonProdCreds: boolean | null = false;
	hasPreviousProdCreds: boolean | null = false;

	changeRequestType: CustomerAppChangeRequestType | null = null;
	changeRequestedByUserName: string | null = null;
	changeRequestedOn: Date | null = null;
	changeRequestComment: string | null = null;
	availableChanges: ICustomerAppChangeRequest[] = [];

	constructor(lic: ICustomerAppLicense) {
		this.clientApplication_Id = lic.clientApplication_Id;
		this.isActivatedInNonProd = lic.isActivatedInNonProd;
		this.isActivatedInProd = lic.isActivatedInProd;
		this.prodLicensedOn = lic.prodLicensedOn;
		this.nonProdLicensedOn = lic.nonProdLicensedOn;
		this.prodLicensedByUserName = lic.prodLicensedByUserName;
		this.nonProdLicensedByUserName = lic.nonProdLicensedByUserName;
		this.testPublicKeyBlobIsCSP = lic.testPublicKeyBlobIsCSP;
		this.prodPublicKeyBlobIsCSP = lic.prodPublicKeyBlobIsCSP;
		this.testJwtSigningPublicKeyThumbprint = lic.testJwtSigningPublicKeyThumbprint;
		this.prodJwtSigningPublicKeyThumbprint = lic.prodJwtSigningPublicKeyThumbprint;
		this.testClientSecretHash = lic.testClientSecretHash;
		this.prodClientSecretHash = lic.prodClientSecretHash;
		this.testClientSecretHash256 = lic.testClientSecretHash256;
		this.prodClientSecretHash256 = lic.prodClientSecretHash256;
		this.hasPreviousNonProdCreds = lic.hasPreviousNonProdCreds;
		this.hasPreviousProdCreds = lic.hasPreviousProdCreds;
		this.changeRequestType = lic.changeRequestType;
		this.changeRequestedByUserName = lic.changeRequestedByUserName;
		this.changeRequestedOn = lic.changeRequestedOn;
		this.changeRequestComment = lic.changeRequestComment;
		this.availableChanges = lic.availableChanges;
	}

	public static GenLicenseFromAppModel(appData: IAppModel, orgId: number): CustomerAppLicense {
		return new CustomerAppLicense({
			clientApplication_Id: appData.Id,
			organization_Id: orgId,
			isActivatedInProd: appData.IsActivatedInProd,
			isActivatedInNonProd: appData.IsActivatedInNonProd,
			prodLicensedOn: null,
			nonProdLicensedOn: null,
			prodLicensedByUserName: null,
			nonProdLicensedByUserName: null,
			testPublicKeyBlobIsCSP: appData.TestPublicKeyBlobIsCSP,
			prodPublicKeyBlobIsCSP: appData.ProdPublicKeyBlobIsCSP,
			testJwtSigningPublicKeyThumbprint: appData.TestJwtSigningPublicKeyThumbprint,
			prodJwtSigningPublicKeyThumbprint: appData.ProdJwtSigningPublicKeyThumbprint,
			testClientSecretHash: appData.TestClientSecretHash,
			prodClientSecretHash: appData.ProdClientSecretHash,
			testClientSecretHash256: appData.TestClientSecretHash256,
			prodClientSecretHash256: appData.ProdClientSecretHash256,
			hasPreviousNonProdCreds: appData.HasPreviousNonProdKeysOrHash,
			hasPreviousProdCreds: appData.HasPreviousProdKeysOrHash,
			changeRequestType: null,
			changeRequestedByUserName: null,
			changeRequestedOn: null,
			changeRequestComment: null,
			availableChanges: [],
		});
	}

	public IsActivated(envType: EnvironmentType): boolean {
		return envType === EnvironmentType.NonProd
			? this.isActivatedInNonProd === true
			: this.isActivatedInProd === true;
	}

	public HasSavedCreds(envType: EnvironmentType, hasJku?: boolean): boolean {
		if (!this.IsActivated(envType)) return false;

		if (hasJku) return true;

		if (envType === EnvironmentType.NonProd) {
			return (
				!isNullOrEmpty(this.testClientSecretHash) ||
				!isNullOrEmpty(this.testClientSecretHash256) ||
				!isNullOrEmpty(this.testJwtSigningPublicKeyThumbprint) ||
				this.testPublicKeyBlobIsCSP
			);
		} else {
			return (
				!isNullOrEmpty(this.prodClientSecretHash) ||
				!isNullOrEmpty(this.prodClientSecretHash256) ||
				!isNullOrEmpty(this.prodJwtSigningPublicKeyThumbprint) ||
				this.prodPublicKeyBlobIsCSP
			);
		}
	}

	public HasSavedCredsAnyEnv(hasNonProdJku?: boolean, hasProdJku?: boolean): boolean {
		return (
			this.HasSavedCreds(EnvironmentType.NonProd, hasNonProdJku) ||
			this.HasSavedCreds(EnvironmentType.Prod, hasProdJku)
		);
	}

	public HasPreviousCreds(envType: EnvironmentType): boolean {
		return envType === EnvironmentType.NonProd
			? this.hasPreviousNonProdCreds === true
			: this.hasPreviousProdCreds === true;
	}
}

/** App created by a customer */
export interface IHomegrownApp extends IBasicApp {
	status: ClientApplicationStatus;
	createdBy: number;
	createdByUserName: string | null;
	createdOn: Date;
	useOAuth2: boolean;
	consumerType: ConsumerTypes;
	isConfidentialClient: boolean;
	nonProdJsonWebKeySetUrl: string | null;
	prodJsonWebKeySetUrl: string | null;
	license: ICustomerAppLicense;

	newestAppVersion: number | undefined;
	hasApiMetricsForOrg: boolean;
	hasApiStatsForOrg: boolean;
	hasOutOfContextAPIs: boolean;
	hasApiContextValidationOverride: boolean;

	isAvailableInGallery: boolean;
	isNewestVersionAvailableInGallery: boolean;

	publicKeysAllowed: PublicKeysAllowed;
	owningOrgName: string;
	getsAppLicensesFromOrganization_Name: string;
}

export interface IUpdateChangeRequestModel {
	AppId: number;
	ChangeType: CustomerAppChangeRequestType | null;
	Comment: string | null;
}

export interface IEpicVersion {
	epicCode: number;
	name: string;
}

export interface ITopLevelComponentProps {
	userSecurity: IUserSecurity;
}

export enum MenuId {
	Toolbar = 1,
	ManageApps = 2,
	FindResources = 3,
	JumpTo = 4,
	UserMenu = 5,
	Admin = 6,
	Login = 7,
	ExploreApps = 8,
}

/** Additional values are defined in the server enum but most are not needed by client code so they are left out */
export enum MenuItemId {
	InterestedOrgs = 6,
	Attestation = 9,
	SwitchOrgs = 53,
	DisabledSherlock = 55,
}

export interface ISiteSearchResult {
	Name: string;
	Link: string;
}
export interface ISiteSearchResults {
	Data: {
		Results: ISiteSearchResult[];
		TotalCount: number;
	};
}

export interface IFavorite {
	Id: number;
	Name: string;
	Link: string;
	MenuId: number;
}

export interface IFavoritesResults {
	Data: {
		Favorites: IFavorite[];
		MaxAllowed: number;
	};
}

export interface ISaveFavoriteResult {
	Data: IFavorite;
}

export interface IRemoteOrg {
	Id: number;
	Name: string;
}

export interface IRemoteOrgResult {
	Data: {
		currentOrg: number;
		availableOrgs: IRemoteOrg[];
	};
}

export interface INotificationCount {
	Data: { Count: number };
}

export interface INotification {
	NotificationId: number;
	DisplayText: string;
	NotificationDatetime: string;
	Unseen: boolean;
	LinkAddress: string;
}

export interface INotificationListResults {
	Data: INotification[];
}

export interface ISendMessageResponse {
	Data: { success: boolean };
}

export interface IIdleTimeResult {
	Data: number;
}

/** Questionnaire */
export enum QuestionType {
	Freetext = 0,
	Radio = 1,
	Checkboxes = 2,
}

export interface IQuestionnaireOption {
	id: number;
	text: string;
	warningText: string;
	supportsFreetext: boolean;
	override: boolean;
}

export interface IQuestionnaireQuestion {
	id: number;
	text: string;
	helpText: string;
	type: QuestionType;
	options: IQuestionnaireOption[];
	responseText: string;
}

export interface ISelectionOption {
	id: number;
	name: string;
}

export interface IFilterDefinition {
	key: string;
	name: string;
	tooltip: string;
	isMultiResponse: boolean;
	hidden?: boolean | false;
	allowExclude?: boolean;
	includeIdInOption?: boolean | false;
	expandedByDefault?: boolean | false;
	options?: ISelectionOption[];
	allowClear?: boolean;
	type?: "input" | "select"; // select is the default
	inputType?: string; // e.g. date
	minValue?: string;
	maxValue?: string;
}

export interface IColumnDefinition {
	key: string;
	name: string;
	tooltip: string;
	minWidth: number;
	maxWidth: number;
	hideInPicker?: boolean | false;
	defaultShow?: boolean | false;
	isSortable?: boolean | true;
	width?: number;
}

export interface IFilterListItem {
	key: string;
	value?: string | undefined;
	values?: (number | string)[] | undefined;
	isExcluded: boolean;
}

export interface IPagination {
	page: number;
	pageSize: number;
}

export interface ISorting {
	sortColumn: string;
	sortDirection: number;
}

export interface IListParameters {
	filters: IFilterListItem[];
	pagination: IPagination;
	sorting: ISorting;
	columns: string[];
}

export enum MoveType {
	Up = 1,
	Down = 2,
	Add = 3,
	Remove = 4,
}

export enum ContactSubject {
	Undefined = 0,
	GeneralInquiry = 1,
	TechnicalQuestion = 2,
	ShowroomInquiry = 3,
}

export interface ITemplateColumns {
	templateColumns: GridProps["templateColumns"];
}

export interface IRowComponentProps<T> extends ITemplateColumns {
	data: T;
	index: number;
	error: any;
	updateOrRemoveRow: (data: T, remove: boolean, index: number) => void;
	ariaLabel: string;
	ariaLabelLowercase: string;
	allowSorting: boolean;
	sortRow: (direction: MoveType, index: number) => void;
	isSortUpDisabled: boolean;
	isSortDownDisabled: boolean;
	isRemoveDisabled: boolean;
	hasFocus: boolean;
	lastMoveType: MoveType | undefined;
	isRowEditable: boolean;
}

export interface IKeyedListObject {
	key?: string;
}

export enum YesNoFilterOptions {
	No = 0,
	Yes = 1,
}